<template>
    <div class="playList_body">
        <div class="chanels_category_list">
        <ul class="categorys_list">
            <li
            v-for="(gener, i) in chanelCategoryData"
            :key="gener"
            :class="[selectedGenerIndex == i ? 'active' : 'noActive']"
            class="single_channel"
            @click="actGenerSelect(gener, i)"
            >
            {{ gener }}
            </li>
        </ul>
        </div>
        <div class="chanel_peograms_list">
        <div class="peograms_list placeholder" v-if="!activeChanelProgramsList || activeChanelProgramsList.length <= 0">
            <WaveLoader :loaderHeight="loaderHeight" />
        </div>
        <ul class="peograms_list" v-else id="programsList" v-on:scroll="handleScroll">
            <li
            v-for="(channel, index) in activeChanelProgramsList"
            :key="index"
            class="single_program"
            @click="actProgramPlay(channel)"
            >
            <div class="poster_img">
                <img :src="getLowPoster(channel)" />
            </div>
            <div class="program_title">
                {{ channel.title || channel.defaulttitle }}
            </div>
            <div class="channelno" v-if="channel.channelno || channel.channelno == 'null'">{{ channel.channelno }}</div>
            </li>
            <li style="text-align: center;" v-if="activeChanelProgramsList.length < totalChannelcount" id="intersectTargetElemnt">LOADING...</li>
            <li style="display: none;width: 100%;height: 100px;position: absolute;bottom: 15px;" id="intersectRootElemnt"></li>
        </ul>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from "vuex";
    import moment from "moment";
    import PlaybackUtilities from "@/mixins/playbackUtilities.js";
    import Utility from "@/mixins/Utility.js";
    import { eventBus } from "@/eventBus";
    export default {
        name: 'ChannelList',
        // props: ['chanelCategoryData', 'activeChanelProgramsList'],
        components: {
            WaveLoader: () => import( /* webpackChunkName: "waveLoader" */ "@/components/Loaders/WaveLoader.vue"),
        },
        computed: {
            ...mapGetters(["appConfig"]),
        },
        data() {
            return {
                placeHolder: {
                    portrait: require("@/assets/icons/PlaceHolderPortrait.png"),
                    landScape: require("@/assets/icons/PlaceHolderLandscape.png"),
                },
                generData: null,
                selectedGenerIndex: 0,
                totalChannelcount: null,
                loaderHeight: "2.5rem",
                activeChanelProgramsList: null,
                currentPageNum: 1,
                selectedGenre: 'ALL CATEGORIES',
                chanelCategoryData: ["ALL CATEGORIES"],
            }
        },
        created() {
            // this.genersData = this.chanelCategoryData;
            eventBus.$on("channelListData", (payLoad) => {
                console.log('====>channelListData', payLoad)
                this.listGenerContent(payLoad).then((data) => {
                    data.data.data["TVCHANEL"].map((item, i) => {
                        this.chanelCategoryData = [
                        ...this.chanelCategoryData,
                        ...Object.keys(item),
                        ];
                    });
                    this.actGenerSelect(this.chanelCategoryData[0], 0);
                });
            });
        },
        mounted() {
            let payLoad = {
                objecttype: "CHANEL",
                page: this.currentPageNum
            };
            eventBus.$emit("channelListData", payLoad);
            this.activeChanelProgramsList = [];
            // this.activeProgramsDate(payLoad);
            // const element = document.getElementById("programsList");
            // element.addEventListener("scroll",  this.debounce(this.handleScroll(), 100));
            this.handleScroll = this.debounceFunction(this.handleScroll, 1500);
            },
        methods: {
            ...mapActions([
                "listContent",
                "listGenerContent",
                "getContent",
            ]),
            getLowPoster(item) {
            let returnImage = this.placeHolder.landScape;
            let index =
                item &&
                item.poster &&
                item.poster.findIndex((data) => {
                return data.postertype === "LANDSCAPE";
                });
            if (index > -1) {
                item.poster[index].filelist.filter((lowImg) => {
                if (lowImg && lowImg.quality === "LOW") {
                    returnImage = lowImg.filename;
                }
                });
            }
            //  else {
            //   debugger;
            //   item.poster[0].filelist.filter((el) => {
            //     if (el.quality === "THUMBNAIL") {
            //       returnImage = el.filename;
            //     }
            //   });
            // }
            return returnImage;
            },
            actGenerSelect(chanel, i) {
                this.selectedGenerIndex = i;
                this.selectedGenre = chanel;
                this.activeChanelProgramsList = null;
                this.currentPageNum = 1;
                window.currentPageIndex = this.currentPageNum;
                let payLoad = {
                    objecttype: "CHANEL",
                    page: this.currentPageNum
                };
                let arr = ["genre", "subgenre"];
                if (i !== 0) {
                    payLoad.genre = chanel;
                    payLoad.subgenre = chanel;
                    payLoad.groupbyor =  JSON.stringify(arr);
                }            
                this.activeChanelProgramsList = [];
                this.activeProgramsDate(payLoad);
            },
            activeProgramsDate(payload) {
                this.listContent(payload).then((singleChanelData) => {
                    this.activeChanelProgramsList = [ ...this.activeChanelProgramsList, ...singleChanelData.data.data ];
                    this.totalChannelcount = singleChanelData.data.totalcount;
                    window.totalChannelcount = this.totalChannelcount;
                });
            },
            actProgramPlay(program) {
                window.selectedGenerIndex = this.selectedGenerIndex;
                window.selectedGenre = this.selectedGenre;
                window.activeChanelProgramsListAll = [];
                window.activeChanelProgramsListAll = [...window.activeChanelProgramsListAll, ...this.activeChanelProgramsList];
                this.$emit('actFromChannelList', program);
            },
            handleIntersect(entries, observer) {
                entries.forEach(entry => {
                if (entry.intersectionRatio === 0.5){
                    console.error("intersected");
                } else {
                    console.error("NotIntersected");
                }
                });
            },

            createObserver() {
                let target = document.getElementById('intersectTargetElemnt');
                let options = {
                root: null,
                rootMargin: '0px',
                threshold: 1.0
                }
                let observer = new IntersectionObserver(this.handleIntersect.bind(this), options);
                observer.observe(target);
            },

            handleScroll() {
                this.currentPageNum = this.currentPageNum + 1;
                window.currentPageIndex = this.currentPageNum;
                let payLoad = {
                    'objecttype' : 'CHANEL',                    
                    page: this.currentPageNum
                    }
                    let arr = ["genre", "subgenre"];
                    if (this.selectedGenerIndex !== 0) {
                        payLoad.genre = this.selectedGenre;
                        payLoad.subgenre = this.selectedGenre;
                        payLoad.groupbyor =  JSON.stringify(arr);
                    }
                    this.activeProgramsDate(payLoad);
            }
        },
        // beforeUnmount() {
        //     window.removeEventListener('scroll', this.handleScroll);
        // },
        mixins: [PlaybackUtilities, Utility],

    }
</script>
<style scoped lang="scss">
    .playList_body{
    padding: 0.5rem;
    width: auto;
    display: flex;
    gap: 0.5rem;    
    height: calc(100% - 30px);
    }
    .chanels_category_list {
    background: rgba(255, 255, 255, 0.35);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    }
    .chanel_peograms_list {
    background: rgba(255, 255, 255, 0.35);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    // height: 100vh;
    font-size: 1rem;
    display: flex;
    align-items: center;
    }
    .peograms_list,
    .categorys_list {
    height: calc(100% - 30px);
    overflow-y: auto;
    overflow-x: hidden;
    }
    .single_channel,
    .single_program {
    padding: 0.8rem 2.5rem;
    cursor: pointer;
    }
    .single_program {
    padding: 0.3rem 3rem !important;
    display: flex;
    gap: 1rem;
    position: relative;
    &:hover {
        background: rgba(0, 0, 0, 30%);
    }
    }
    .channelno {
    position: absolute;
    right: 3px;
    }
    .poster_img {
        display: flex;
    }
    .poster_img > img {
    width: 75px;
    aspect-ratio: 16/9;
    }
    .active {
        background: linear-gradient(to right, rgba(184, 20, 0, 1), rgba(255, 0, 0, 1), rgba(184, 20, 0, 1));
    }
    .placeholder {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    ul li {
    margin: 0.6rem 0rem;
    }
</style>